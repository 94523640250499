// import { showModal } from '../../components/ui/Modal/Modal';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  orders: [],
  unseenOrders: [],
  unseenOrdersCount: 0,
  unresolvedCount: 0,
  unresolvedLoading: false,
  ordersLoading: false,
  ordersError: false,
  updateOrderLoading: false,
  updateOrderError: false,
}

const fetchUnresolvedStart = (state, action) => {
  return {
    ...state,
    unresolvedLoading: true,
    unresolvedError: false,
  }
}
const fetchUnresolvedSuccess = (state, action) => {
  const {unresolvedCount} = action;

  return {
    ...state,
    unresolvedCount: +unresolvedCount,
    unresolvedLoading: false,
    unresolvedError: false,
  }
}
const fetchUnresolvedFailed = (state, action) => {
  return {
    ...state,
    unresolvedLoading: false,
    unresolvedError: true,
  }
}

const fetchOrdersStart = (state, action) => {
  return {
    ...state,
    ordersLoading: true,
    ordersError: false,
  }
}
const fetchOrdersSuccess = (state, action) => {
  const {orders, resultsCount, unresolvedCount} = action;
  const unseenOrders = orders.filter(order => order.is_resolved !== '1');
  const unseenOrdersCount = unseenOrders.length;

  return {
    ...state,
    orders,
    resultsCount,
    unseenOrders,
    unseenOrdersCount,
    unresolvedCount: +unresolvedCount,
    ordersLoading: false,
    ordersError: false,
  }
}
const fetchOrdersFailed = (state, action) => {
  return {
    ...state,
    ordersLoading: false,
    ordersError: true,
  }
}


const updateOrderStart = (state, action) => {
  return {
    ...state,
    updateOrderLoading: true,
    updateOrderError: false,
  }
}
const updateOrderSuccess = (state, action) => {
  // const { updatedOrder } = action;
  // const orders = state.orders.map(order => {
  //   if (order.id === updatedOrder.id)
  //     return updatedOrder;

  //   return order;
  // });
  
  // const unseenOrders = orders.filter(order => !order.opened);
  // const unseenOrdersCount = unseenOrders.length;

  return {
    ...state,
    // orders,
    // unseenOrders,
    // unseenOrdersCount,
    updateOrderLoading: false,
    updateOrderError: false,
  }
}
const updateOrderFailed = (state, action) => {
  return {
    ...state,
    updateOrderLoading: false,
    updateOrderError: true,
  }
}



const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_STATE: return initialState;

    case actionTypes.FETCH_UNRESOLVED_START: return fetchUnresolvedStart(state, action);
    case actionTypes.FETCH_UNRESOLVED_SUCCESS: return fetchUnresolvedSuccess(state, action);
    case actionTypes.FETCH_UNRESOLVED_FAILED: return fetchUnresolvedFailed(state, action);

    case actionTypes.FETCH_ORDERS_START: return fetchOrdersStart(state, action);
    case actionTypes.FETCH_ORDERS_SUCCESS: return fetchOrdersSuccess(state, action);
    case actionTypes.FETCH_ORDERS_FAILED: return fetchOrdersFailed(state, action);

    case actionTypes.UPDATE_ORDER_START: return updateOrderStart(state, action);
    case actionTypes.UPDATE_ORDER_SUCCESS: return updateOrderSuccess(state, action);
    case actionTypes.UPDATE_ORDER_FAILED: return updateOrderFailed(state, action);

    default: return state;
  }
}

export default reducer;