import axios from 'axios';
import { put } from 'redux-saga/effects';
import { notify } from '../../components/ui/Notification/Notification';
import { API_URL } from '../../config';
import * as actionCreators from '../actions/index';

export function* fetchUnresolvedSaga(action) {
  yield put(actionCreators.fetchUnresolvedStart());

  const { jwt, callback } = action;
  try {
    const res = yield axios.get(`${API_URL}/order/unresolved.php`, {
      headers: {
        Authorization: jwt
      }
    });

    const unresolvedCount = res.data[0].unresolvedCount;

    yield put(actionCreators.fetchUnresolvedSuccess(unresolvedCount));

    if (callback)
      yield callback();
  } catch (error) {
    yield notify('Greška u konekciji', 'Danger');
    yield put(actionCreators.fetchUnresolvedFailed(error));
  }
}

export function* fetchOrdersSaga(action) {
  yield put(actionCreators.fetchOrdersStart());

  const { searchData, pageNumber, resPerPage, jwt, callback } = action;
  try {
    const res = yield axios.post(`${API_URL}/order/search.php`, { ...searchData, pageNumber, resPerPage }, {
      headers: {
        Authorization: jwt
      }
    });
    const orders = res.data[0].data;
    const resultsCount = res.data[0].resultsCount;
    const unresolvedCount = res.data[0].unresolvedCount;

    yield put(actionCreators.fetchOrdersSuccess(orders, resultsCount, unresolvedCount));

    if (callback)
      yield callback();
  } catch (error) {
    yield notify('Greška u konekciji', 'Danger');
    yield put(actionCreators.fetchOrdersFailed(error));
  }
}

export function* updateOrderSaga(action) {
  const { jwt, data } = action;

  try {
    yield put(actionCreators.updateOrderStart());

    console.log({ jwt, data })
    yield axios.put(`${API_URL}/order/update.php`, data,
      {
        headers: {
          Authorization: jwt
        }
      });

    yield put(actionCreators.updateOrderSuccess());
    notify('Izmena sačuvana!', 'Success');

    if (action.callback)
      yield action.callback();
  } catch (error) {
    console.log(error)
    notify('Došlo je do greške!', 'Danger');
    yield put(actionCreators.updateOrderFailed(error));
  }
}